
import { defineComponent, reactive } from 'vue';
import InputSwitch from './InputSwitch.vue';
import InputCheckbox from '@/components/Input/Checkbox/InputCheckbox.vue';
import InputText from '@/components/Input/Text/InputText.vue';
import InputSelect from '@/components/Input/Select/InputSelect.vue';
import { mockProps } from './mockup';
import { sizes } from '@/models/components/size.model';

export default defineComponent({
  name: 'SampleInputSwitch',
  components: {
    InputSwitch,
    InputCheckbox,
    InputText,
    InputSelect,
  },
  setup() {
    return {
      props: reactive(mockProps),
      sizes,
    };
  },
});
